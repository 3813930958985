async function ensureJWeb() {
  const JWebRegistry = (window as any)?.JWebRegistry;
  return JWebRegistry?.jWebReady
    .then((JWeb) => {
      // List of plugins that we need to guarantee that's provided
      if (JWeb && JWeb.Plugins && JWeb.Plugins.Auth) {
        return;
      } else {
        console.error(
          'jWebReady: unable to obtain AuthPlugin (missing JWeb objects)'
        );
      }
    })
    .catch((error) => {
      console.error(`jWebReady: unable to obtain AuthPlugin (error: ${error})`);
    });
}

export async function displayNativeAuth() {
  await ensureJWeb();
  const JWebAuthPlugin = (window as any)?.JWeb.Plugins.Auth;
  const tokenProviderOptions = {
    tokenType: 'user',
    allowUserInteraction: true
  };
  return JWebAuthPlugin.getToken({ tokenProviderOptions });
}

export async function isNative() {
  await ensureJWeb();
  const JWeb = (window as any)?.JWeb;
  return !!JWeb?.isNative;
}
